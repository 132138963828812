var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import { ConfigContext } from '../config-provider';
const spaceSize = {
    small: 8,
    middle: 16,
    large: 24,
};
const Space = props => {
    const { getPrefixCls, space, direction: directionConfig } = React.useContext(ConfigContext);
    const { size = (space === null || space === void 0 ? void 0 : space.size) || 'small', className, children, direction = 'horizontal', prefixCls: customizePrefixCls } = props, otherProps = __rest(props, ["size", "className", "children", "direction", "prefixCls"]);
    const items = toArray(children);
    const len = items.length;
    if (len === 0) {
        return null;
    }
    const prefixCls = getPrefixCls('space', customizePrefixCls);
    const cn = classNames(prefixCls, `${prefixCls}-${direction}`, { [`${prefixCls}-rtl`]: directionConfig === 'rtl' }, className);
    const itemClassName = `${prefixCls}-item`;
    const marginDirection = directionConfig === 'rtl' ? 'marginLeft' : 'marginRight';
    return (<div className={cn} {...otherProps}>
      {items.map((child, i) => (<div className={itemClassName} 
    // eslint-disable-next-line react/no-array-index-key
    key={`${itemClassName}-${i}`} style={i === len - 1
        ? {}
        : {
            [direction === 'vertical' ? 'marginBottom' : marginDirection]: typeof size === 'string' ? spaceSize[size] : size,
        }}>
          {child}
        </div>))}
    </div>);
};
export default Space;
