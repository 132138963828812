import * as React from 'react';
import RcSwitch from 'rc-switch';
import classNames from 'classnames';
import omit from 'omit.js';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Wave from '../_util/wave';
import { ConfigConsumer } from '../config-provider';
import warning from '../_util/warning';
import SizeContext from '../config-provider/SizeContext';
export default class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.saveSwitch = (node) => {
            this.rcSwitch = node;
        };
        this.renderSwitch = ({ getPrefixCls, direction }) => {
            const { prefixCls: customizePrefixCls, size: customizeSize, loading, className = '', disabled, } = this.props;
            const prefixCls = getPrefixCls('switch', customizePrefixCls);
            const loadingIcon = loading ? (<LoadingOutlined className={`${prefixCls}-loading-icon`}/>) : null;
            return (<SizeContext.Consumer>
        {size => {
                const classes = classNames(className, {
                    [`${prefixCls}-small`]: (customizeSize || size) === 'small',
                    [`${prefixCls}-loading`]: loading,
                    [`${prefixCls}-rtl`]: direction === 'rtl',
                });
                return (<Wave insertExtraNode>
              <RcSwitch {...omit(this.props, ['loading'])} prefixCls={prefixCls} className={classes} disabled={disabled || loading} ref={this.saveSwitch} loadingIcon={loadingIcon}/>
            </Wave>);
            }}
      </SizeContext.Consumer>);
        };
        warning('checked' in props || !('value' in props), 'Switch', '`value` is not a valid prop, do you mean `checked`?');
    }
    focus() {
        this.rcSwitch.focus();
    }
    blur() {
        this.rcSwitch.blur();
    }
    render() {
        return <ConfigConsumer>{this.renderSwitch}</ConfigConsumer>;
    }
}
Switch.__ANT_SWITCH = true;
