import * as React from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import FilterFilled from '@ant-design/icons/FilterFilled';
import Button from '../../../button';
import Menu from '../../../menu';
import Checkbox from '../../../checkbox';
import Radio from '../../../radio';
import Dropdown from '../../../dropdown';
import FilterDropdownMenuWrapper from './FilterWrapper';
import useSyncState from '../useSyncState';
const { SubMenu, Item: MenuItem } = Menu;
function hasSubMenu(filters) {
    return filters.some(({ children }) => children);
}
function renderFilterItems(filters, prefixCls, filteredKeys, multiple) {
    return filters.map((filter, index) => {
        const key = String(filter.value);
        if (filter.children) {
            return (<SubMenu key={key || index} title={filter.text} popupClassName={`${prefixCls}-dropdown-submenu`}>
          {renderFilterItems(filter.children, prefixCls, filteredKeys, multiple)}
        </SubMenu>);
        }
        const Component = multiple ? Checkbox : Radio;
        return (<MenuItem key={filter.value !== undefined ? key : index}>
        <Component checked={filteredKeys.includes(key)}/>
        <span>{filter.text}</span>
      </MenuItem>);
    });
}
function FilterDropdown(props) {
    const { prefixCls, column, dropdownPrefixCls, columnKey, filterMultiple, filterState, triggerFilter, locale, children, getPopupContainer, } = props;
    const { filterDropdownVisible, onFilterDropdownVisibleChange } = column;
    const [visible, setVisible] = React.useState(false);
    const filtered = !!(filterState &&
        (filterState.filteredKeys || filterState.forceFiltered));
    const triggerVisible = (newVisible) => {
        setVisible(newVisible);
        if (onFilterDropdownVisibleChange) {
            onFilterDropdownVisibleChange(newVisible);
        }
    };
    const mergedVisible = typeof filterDropdownVisible === 'boolean' ? filterDropdownVisible : visible;
    // ===================== Select Keys =====================
    const propFilteredKeys = filterState && filterState.filteredKeys;
    const [getFilteredKeysSync, setFilteredKeysSync] = useSyncState(propFilteredKeys || []);
    const onSelectKeys = ({ selectedKeys }) => {
        setFilteredKeysSync(selectedKeys);
    };
    React.useEffect(() => {
        onSelectKeys({ selectedKeys: propFilteredKeys || [] });
    }, [propFilteredKeys]);
    // ====================== Open Keys ======================
    const [openKeys, setOpenKeys] = React.useState([]);
    const openRef = React.useRef();
    const onOpenChange = (keys) => {
        openRef.current = window.setTimeout(() => {
            setOpenKeys(keys);
        });
    };
    const onMenuClick = () => {
        window.clearTimeout(openRef.current);
    };
    React.useEffect(() => {
        return () => {
            window.clearTimeout(openRef.current);
        };
    }, []);
    // ======================= Submit ========================
    const internalTriggerFilter = (keys) => {
        triggerVisible(false);
        const mergedKeys = keys && keys.length ? keys : null;
        if (mergedKeys === null && (!filterState || !filterState.filteredKeys)) {
            return null;
        }
        if (isEqual(mergedKeys, filterState === null || filterState === void 0 ? void 0 : filterState.filteredKeys)) {
            return null;
        }
        triggerFilter({
            column,
            key: columnKey,
            filteredKeys: mergedKeys,
        });
    };
    const onConfirm = () => {
        internalTriggerFilter(getFilteredKeysSync());
    };
    const onReset = () => {
        setFilteredKeysSync([]);
        internalTriggerFilter([]);
    };
    const onVisibleChange = (newVisible) => {
        triggerVisible(newVisible);
        // Default will filter when closed
        if (!newVisible && !column.filterDropdown) {
            onConfirm();
        }
    };
    // ======================== Style ========================
    const dropdownMenuClass = classNames({
        [`${dropdownPrefixCls}-menu-without-submenu`]: !hasSubMenu(column.filters || []),
    });
    let dropdownContent;
    if (typeof column.filterDropdown === 'function') {
        dropdownContent = column.filterDropdown({
            prefixCls: `${dropdownPrefixCls}-custom`,
            setSelectedKeys: (selectedKeys) => onSelectKeys({ selectedKeys }),
            selectedKeys: getFilteredKeysSync(),
            confirm: onConfirm,
            clearFilters: onReset,
            filters: column.filters,
            visible: mergedVisible,
        });
    }
    else if (column.filterDropdown) {
        dropdownContent = column.filterDropdown;
    }
    else {
        const selectedKeys = (getFilteredKeysSync() || []);
        dropdownContent = (<>
        <Menu multiple={filterMultiple} prefixCls={`${dropdownPrefixCls}-menu`} className={dropdownMenuClass} onClick={onMenuClick} onSelect={onSelectKeys} onDeselect={onSelectKeys} selectedKeys={selectedKeys} getPopupContainer={getPopupContainer} openKeys={openKeys} onOpenChange={onOpenChange}>
          {renderFilterItems(column.filters || [], prefixCls, getFilteredKeysSync(), filterMultiple)}
        </Menu>
        <div className={`${prefixCls}-dropdown-btns`}>
          <Button type="link" size="small" disabled={selectedKeys.length === 0} onClick={onReset}>
            {locale.filterReset}
          </Button>
          <Button type="primary" size="small" onClick={onConfirm}>
            {locale.filterConfirm}
          </Button>
        </div>
      </>);
    }
    const menu = (<FilterDropdownMenuWrapper className={`${prefixCls}-dropdown`}>
      {dropdownContent}
    </FilterDropdownMenuWrapper>);
    let filterIcon;
    if (typeof column.filterIcon === 'function') {
        filterIcon = column.filterIcon(filtered);
    }
    else if (column.filterIcon) {
        filterIcon = column.filterIcon;
    }
    else {
        filterIcon = <FilterFilled />;
    }
    return (<div className={classNames(`${prefixCls}-column`)}>
      <span className={`${prefixCls}-column-title`}>{children}</span>

      <span className={classNames(`${prefixCls}-trigger-container`, {
        [`${prefixCls}-trigger-container-open`]: mergedVisible,
    })} onClick={e => {
        e.stopPropagation();
    }}>
        <Dropdown overlay={menu} trigger={['click']} visible={mergedVisible} onVisibleChange={onVisibleChange} getPopupContainer={getPopupContainer} placement="bottomRight">
          <span role="button" tabIndex={-1} className={classNames(`${prefixCls}-trigger`, {
        active: filtered,
    })}>
            {filterIcon}
          </span>
        </Dropdown>
      </span>
    </div>);
}
export default FilterDropdown;
