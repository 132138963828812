var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from 'react';
import Animate from 'rc-animate';
import addEventListener from 'rc-util/lib/Dom/addEventListener';
import classNames from 'classnames';
import omit from 'omit.js';
import { throttleByAnimationFrameDecorator } from '../_util/throttleByAnimationFrame';
import { ConfigConsumer } from '../config-provider';
import getScroll from '../_util/getScroll';
import scrollTo from '../_util/scrollTo';
export default class BackTop extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            visible: false,
        };
        this.getDefaultTarget = () => {
            return this.node && this.node.ownerDocument ? this.node.ownerDocument : window;
        };
        this.saveDivRef = (node) => {
            this.node = node;
        };
        this.scrollToTop = (e) => {
            const { onClick, target } = this.props;
            scrollTo(0, {
                getContainer: target || this.getDefaultTarget,
            });
            if (typeof onClick === 'function') {
                onClick(e);
            }
        };
        this.renderBackTop = ({ getPrefixCls, direction }) => {
            const { prefixCls: customizePrefixCls, className = '' } = this.props;
            const prefixCls = getPrefixCls('back-top', customizePrefixCls);
            const classString = classNames(prefixCls, className, {
                [`${prefixCls}-rtl`]: direction === 'rtl',
            });
            // fix https://fb.me/react-unknown-prop
            const divProps = omit(this.props, [
                'prefixCls',
                'className',
                'children',
                'visibilityHeight',
                'target',
                'visible',
            ]);
            return (<div {...divProps} className={classString} onClick={this.scrollToTop} ref={this.saveDivRef}>
        {this.renderChildren({ prefixCls })}
      </div>);
        };
    }
    componentDidMount() {
        this.bindScrollEvent();
    }
    componentDidUpdate(prevProps) {
        const { target } = this.props;
        if (prevProps.target !== target) {
            this.bindScrollEvent();
        }
    }
    componentWillUnmount() {
        if (this.scrollEvent) {
            this.scrollEvent.remove();
        }
        this.handleScroll.cancel();
    }
    bindScrollEvent() {
        if (this.scrollEvent) {
            this.scrollEvent.remove();
        }
        const { target } = this.props;
        const getTarget = target || this.getDefaultTarget;
        const container = getTarget();
        this.scrollEvent = addEventListener(container, 'scroll', (e) => {
            this.handleScroll(e);
        });
        this.handleScroll({
            target: container,
        });
    }
    getVisible() {
        if ('visible' in this.props) {
            return this.props.visible;
        }
        return this.state.visible;
    }
    handleScroll(e) {
        const { visibilityHeight = 0 } = this.props;
        const scrollTop = getScroll(e.target, true);
        this.setState({
            visible: scrollTop > visibilityHeight,
        });
    }
    renderChildren({ prefixCls }) {
        const { children } = this.props;
        const defaultElement = (<div className={`${prefixCls}-content`}>
        <div className={`${prefixCls}-icon`}/>
      </div>);
        return (<Animate component="" transitionName="fade">
        {this.getVisible() ? <div>{children || defaultElement}</div> : null}
      </Animate>);
    }
    render() {
        return <ConfigConsumer>{this.renderBackTop}</ConfigConsumer>;
    }
}
BackTop.defaultProps = {
    visibilityHeight: 400,
};
__decorate([
    throttleByAnimationFrameDecorator()
], BackTop.prototype, "handleScroll", null);
