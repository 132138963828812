import { Rule } from 'rc-field-form/lib/interface';
import InternalForm, { useForm, FormInstance, FormProps } from './Form';
import Item, { FormItemProps } from './FormItem';
import List from './FormList';
import { FormProvider } from './context';
import warning from '../_util/warning';
const Form = InternalForm;
Form.Item = Item;
Form.List = List;
Form.useForm = useForm;
Form.Provider = FormProvider;
Form.create = () => {
    warning(false, 'Form', 'antd v4 removed `Form.create`. Please remove or use `@ant-design/compatible` instead.');
};
export { FormInstance, FormProps, FormItemProps, Rule };
export default Form;
