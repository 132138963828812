import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import Animate from 'rc-animate';
import classNames from 'classnames';
import { ConfigConsumer } from '../config-provider';
import getDataOrAriaProps from '../_util/getDataOrAriaProps';
import ErrorBoundary from './ErrorBoundary';
function noop() { }
const iconMapFilled = {
    success: CheckCircleFilled,
    info: InfoCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
};
const iconMapOutlined = {
    success: CheckCircleOutlined,
    info: InfoCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
};
export default class Alert extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            closing: false,
            closed: false,
        };
        this.handleClose = (e) => {
            e.preventDefault();
            const dom = ReactDOM.findDOMNode(this);
            dom.style.height = `${dom.offsetHeight}px`;
            // Magic code
            // 重复一次后才能正确设置 height
            dom.style.height = `${dom.offsetHeight}px`;
            this.setState({
                closing: true,
            });
            (this.props.onClose || noop)(e);
        };
        this.animationEnd = () => {
            this.setState({
                closing: false,
                closed: true,
            });
            (this.props.afterClose || noop)();
        };
        this.renderAlert = ({ getPrefixCls, direction }) => {
            const { description, prefixCls: customizePrefixCls, message, closeText, banner, className = '', style, icon, onMouseEnter, onMouseLeave, onClick, } = this.props;
            let { closable, type, showIcon } = this.props;
            const { closing, closed } = this.state;
            const prefixCls = getPrefixCls('alert', customizePrefixCls);
            // banner模式默认有 Icon
            showIcon = banner && showIcon === undefined ? true : showIcon;
            // banner模式默认为警告
            type = banner && type === undefined ? 'warning' : type || 'info';
            // use outline icon in alert with description
            const iconType = (description ? iconMapOutlined : iconMapFilled)[type] || null;
            // closeable when closeText is assigned
            if (closeText) {
                closable = true;
            }
            const alertCls = classNames(prefixCls, `${prefixCls}-${type}`, {
                [`${prefixCls}-closing`]: closing,
                [`${prefixCls}-with-description`]: !!description,
                [`${prefixCls}-no-icon`]: !showIcon,
                [`${prefixCls}-banner`]: !!banner,
                [`${prefixCls}-closable`]: closable,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className);
            const closeIcon = closable ? (<button type="button" onClick={this.handleClose} className={`${prefixCls}-close-icon`} tabIndex={0}>
        {closeText ? (<span className={`${prefixCls}-close-text`}>{closeText}</span>) : (<CloseOutlined />)}
      </button>) : null;
            const dataOrAriaProps = getDataOrAriaProps(this.props);
            const iconNode = (icon &&
                (React.isValidElement(icon) ? (React.cloneElement(icon, {
                    className: classNames(`${prefixCls}-icon`, {
                        [icon.props.className]: icon.props.className,
                    }),
                })) : (<span className={`${prefixCls}-icon`}>{icon}</span>))) ||
                React.createElement(iconType, { className: `${prefixCls}-icon` });
            return closed ? null : (<Animate component="" showProp="data-show" transitionName={`${prefixCls}-slide-up`} onEnd={this.animationEnd}>
        <div data-show={!closing} className={alertCls} style={style} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} {...dataOrAriaProps}>
          {showIcon ? iconNode : null}
          <span className={`${prefixCls}-message`}>{message}</span>
          <span className={`${prefixCls}-description`}>{description}</span>
          {closeIcon}
        </div>
      </Animate>);
        };
    }
    render() {
        return <ConfigConsumer>{this.renderAlert}</ConfigConsumer>;
    }
}
Alert.ErrorBoundary = ErrorBoundary;
